import React, { ReactElement } from 'react'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { Link } from 'gatsby'

import colors from '../lib/colors'
import { PotentialNewlineSeparator } from './separator'

const Wrapper = styled.footer`
  padding: 0 1em 0.5em;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 0.25rem 0;
`
const FooterLine = styled.span`
  background: ${colors.offWhite};
  display: block;
  flex: 1 1;
  height: 2px;
`

const FooterSocials = styled.span`
  display: block;
  flex: 0 0;
  font-size: 0.75rem;
  line-height: 1.2rem;
  margin-left: 2em;
  text-align: right;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
`

const FooterLegal = styled(FooterSocials)`
  flex: 0 1 100%;
  font-size: 0.5rem;
  line-height: 1rem;
  margin: 1em 0 0;
  text-align: center;

  @media (min-width: 600px) {
    margin: 0.5em 0;
    position: relative;
    text-align: left;
    top: 0.2em;
  }
`

const SocialLinkContainer = styled.span`
  display: inline-block;
  margin: 0 0 0 4px;
`

const SocialLink = styled.a`
  color: ${colors.offWhite};
  display: inline-block;
  font-size: 1.2rem;
  margin: 0 4px;
  vertical-align: top;
`

const AdditionalLinks = styled.div`
  margin: 2em 0;
  text-align: center;
`

function Footer(): ReactElement {
  return (
    <Wrapper>
      <AdditionalLinks>
        {/* <a
          href="https://starkitchen.hilinemerch.com/"
          rel="noreferrer"
          target="_blank"
        >
          Merch
        </a>
        <PotentialNewlineSeparator>/</PotentialNewlineSeparator> */}
        <a href="https://2nu.gs/StarKitchen" rel="noreferrer" target="_blank">
          Live Music on Nugs.net
        </a>
        <PotentialNewlineSeparator>/</PotentialNewlineSeparator>
        <a
          href="https://www.facebook.com/StarKitchenBand"
          rel="noreferrer"
          target="_blank"
        >
          Facebook
        </a>
        <PotentialNewlineSeparator>/</PotentialNewlineSeparator>
        <a
          href="https://www.instagram.com/StarKitchenBand"
          rel="noreferrer"
          target="_blank"
        >
          Instagram
        </a>
        <PotentialNewlineSeparator>/</PotentialNewlineSeparator>
        <a
          href="https://twitter.com/StarKitchenBand"
          rel="noreferrer"
          target="_blank"
        >
          Twitter
        </a>
        <PotentialNewlineSeparator>/</PotentialNewlineSeparator>
        <a
          href="https://open.spotify.com/artist/7pHa2zdgnRHA8Dnk9cPB5f"
          rel="noreferrer noopener"
          target="_blank"
        >
          Spotify
        </a>
        <PotentialNewlineSeparator>/</PotentialNewlineSeparator>
        <a
          href="https://music.apple.com/artist/star-kitchen/1518920442"
          rel="noreferrer noopener"
          target="_blank"
        >
          Apple&nbsp;Music
        </a>
      </AdditionalLinks>
      <Container>
        <FooterLine />
        <FooterSocials>
          @StarKitchenBand:
          <SocialLinkContainer>
            <SocialLink
              href="https://www.instagram.com/StarKitchenBand"
              title="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </SocialLink>
            <SocialLink
              href="https://www.twitter.com/StarKitchenBand"
              title="Twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/StarKitchenBand"
              title="Facebook"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </SocialLink>
          </SocialLinkContainer>
        </FooterSocials>
      </Container>
      <Container>
        <FooterLegal>
          © {new Date().getFullYear()}
          {` `}•{` `}
          <Link to="/privacy/">Privacy Policy</Link>
          {` `}•{` `}
          <Link to="/terms/">Terms Of Use</Link>
        </FooterLegal>
      </Container>
    </Wrapper>
  )
}

export default Footer
