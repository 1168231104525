import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const LogoContainer = styled.div`
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  width: 400px;
`

export default function StarKitchenTriangle(): ReactElement {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/star-kitchen-triangle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <LogoContainer>
      <Img
        fluid={data.file.childImageSharp.fluid}
        fadeIn={false}
        alt=""
        critical={false}
        loading="eager"
        style={{ left: '2%' }}
      />
    </LogoContainer>
  )
}
