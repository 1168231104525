import React, { ReactElement } from 'react'
import styled from 'styled-components'
import colors from '../lib/colors'

import titleSvg from '../images/star-kitchen-title.svg'

const Container = styled.h1`
  color: ${colors.offWhite};
  font-weight: bold;
  margin: 5% 0 0;
  max-width: 100%;
  text-align: center;
  text-shadow: #000 0.25em 0.25em;
  text-transform: uppercase;
  width: 300px;
`

export default function StarKitchenTitle(): ReactElement {
  return (
    <Container>
      <img src={titleSvg} alt="Star Kitchen" style={{ width: '90%' }} />
    </Container>
  )
}
