import React, { FC, ReactElement } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import StarKitchenTriangle from '../components/star-kitchen-triangle'
import StarKitchenTitle from '../components/star-kitchen-title'

const HeaderWrapper = styled.header`
  /* background: #0f0f0f; */
`

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 960;
  padding: 1.45rem 1.0875rem;
`

const SiteLogo = styled.div`
  flex: 0 0 100px;
  font-size: 0;
  margin: 0 1rem 0 0;
  padding: 0;
  width: 100px;
`

// const PageTitle = styled.h2`
//   color: #f0f0f0;
//   margin: 0;
//   padding: 0;
// `

interface HeaderProps {
  title: string
  siteTitle: string
}

const Header: FC<HeaderProps> = (_props: HeaderProps): ReactElement => (
  <HeaderWrapper>
    <HeaderContainer>
      <SiteLogo>
        <Link to="/">
          <StarKitchenTriangle />
          <StarKitchenTitle />
        </Link>
      </SiteLogo>
      {/*title ? <PageTitle>{title}</PageTitle> : null*/}
    </HeaderContainer>
  </HeaderWrapper>
)

export default Header
