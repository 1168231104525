/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { ReactElement } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import SEO from '@martiandev/seo'
import Header from './header'
import Nav from './nav'
import Footer from './footer'
// import './layout.scss'

import colors from '../lib/colors'
import bgTall from '../images/SK-fall19-bg-tall.jpg'
import bgWide from '../images/SK-fall19-bg-wide.jpg'
import 'typeface-league-spartan'
import { ReactNode } from 'react'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  * {
    box-sizing: border-box;
  }
  body {
    background: #000 url('${bgTall}') repeat;
    color: ${colors.offWhite};
    font-display: swap;
    font-family: 'League Spartan', sans-serif;
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }
  a {
    color: ${colors.orange};
    :hover {
      color: #fff;
    }
  }
  @media (min-width: 769px) {
    background: #000 url('${bgWide}') repeat;
  }
`

const Main = styled.main`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
`

interface LayoutProps {
  canonical?: string
  children?: ReactNode
  currentNav?: string
  description?: string
  image?: string
  showNav?: boolean
  title?: string
}

function Layout(props: LayoutProps): ReactElement {
  const {
    title = ``,
    description = ``,
    image = ``,
    canonical = `/`,
    showNav = true,
    currentNav = ``,
    children,
  } = props
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              author
              baseUrl
              description
              fbAppId
              image
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <SEO
            canonical={canonical}
            title={title}
            description={description}
            image={image}
            siteMetadata={data.site.siteMetadata}
          />
          <GlobalStyle />
          {showNav ? (
            <>
              <Header title={title} siteTitle={data.site.siteMetadata.title} />
              <Nav currentNav={currentNav} />
            </>
          ) : null}
          <Main>{children}</Main>
          <Footer />
        </>
      )}
    />
  )
}

export default Layout
