import styled from 'styled-components'

const Separator = styled.span`
  display: inline-block;
  margin: 0 0.5em;
`

export const PotentialNewlineSeparator = styled(Separator)`
  display: block;
  height: 1px;
  margin: 0;
  overflow: hidden;
  width: 1px;

  @media (min-width: 768px) {
    display: inline-block;
    height: auto;
    margin: 0 0.5em;
    position: relative;
    top: 0.125em;
    width: auto;
  }
`

export default Separator
