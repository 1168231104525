/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react'
import { Helmet } from 'react-helmet'

interface MetaItem {
  name?: string
  property?: string
  content: string
}

interface SiteMetadata {
  author?: string
  baseUrl?: string
  description?: string
  fbAppId?: string
  image?: string
  title?: string
}

export interface SEOProps {
  canonical?: string
  children?: []
  description?: string
  lang?: string
  keywords?: string[]
  title: string
  image?: string
  siteMetadata: SiteMetadata
}

function SEO(props: SEOProps) {
  const {
    canonical,
    children,
    description,
    lang,
    keywords,
    title,
    image,
    siteMetadata = {},
  } = props
  // const site = {
  //   siteMetadata: {
  //     title: "Rob Marscher",
  //     description: "",
  //     author: "@robmarscher"
  //   }
  // }

  const baseUrl = siteMetadata.baseUrl || ''
  const metaDescription = description || siteMetadata.description
  const shareImage = image || siteMetadata.image
  const pageTitle = title || siteMetadata.title
  const metaItems = [
    {
      name: `description`,
      content: metaDescription,
    } as MetaItem,
    {
      property: `og:title`,
      content: pageTitle,
    } as MetaItem,
    {
      property: `og:description`,
      content: metaDescription,
    } as MetaItem,
    {
      property: `og:type`,
      content: `website`,
    } as MetaItem,
    {
      property: `og:image`,
      content: shareImage,
    } as MetaItem,
    {
      name: `twitter:card`,
      content: `summary`,
    } as MetaItem,
    {
      name: `twitter:creator`,
      content: siteMetadata.author,
    } as MetaItem,
    {
      name: `twitter:title`,
      content: pageTitle,
    } as MetaItem,
    {
      name: `twitter:description`,
      content: metaDescription,
    } as MetaItem,
  ].concat(
    keywords && keywords.length > 0
      ? ({
          name: `keywords`,
          content: keywords.join(`, `),
        } as MetaItem)
      : [],
  )

  return (
    <Helmet>
      <html lang={lang} />
      <title>{pageTitle}</title>
      {canonical ? <link rel="canonical" href={baseUrl + canonical} /> : null}
      {/* Could add this: <base target="_blank" href="http://mysite.com/" /> */}
      {metaItems.map((item, key) => (
        <meta key={key} {...item} />
      ))}
      {canonical ? (
        <meta property="og:url" content={baseUrl + canonical} />
      ) : null}
      {siteMetadata.fbAppId ? (
        <meta property="fb:app_id" content={siteMetadata.fbAppId} />
      ) : null}
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
}

export default SEO
