import React, { ReactElement, FC } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../lib/colors'
import StarKitchenThreeDots from '../components/star-kitchen-three-dots'

const NavWrapper = styled.header`
  position: absolute;
  top: 1.45rem;
  right: 0;
  text-align: right;
`

const NavContainer = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 0.5rem 1.0875rem;
`
const NavItemContainer = styled.div`
  display: flex;
  margin: 0 -0.5rem;
  max-width: 300px;
  justify-content: space-between;
`

const NavItem = styled.div`
  padding: 0.25rem 0.5rem;
`

const items = [
  // {
  //   path: '/',
  //   title: 'Home',
  // },
  // {
  //   path: '/tour/',
  //   title: 'Tour',
  // },
  // {
  //   path: '/music/',
  //   title: 'Music',
  // },
  // {
  //   path: '/updates/',
  //   title: 'Updates',
  // },
  {
    path: '/about/',
    title: 'About',
  },
]

interface NavProps {
  currentNav?: string
}

const Nav: FC<NavProps> = ({ currentNav }: NavProps): ReactElement => (
  <NavWrapper>
    <NavContainer>
      <StarKitchenThreeDots />
      <NavItemContainer>
        {items.map((item) => (
          <NavItem key={item.path}>
            <Link
              to={item.path}
              style={{
                color:
                  currentNav === item.title ? colors.offWhite : colors.yellow,
                display: `block`,
                fontWeight: currentNav === item.title ? 600 : 300,
                textDecoration: `none`,
              }}
            >
              {item.title}
            </Link>
          </NavItem>
        ))}
      </NavItemContainer>
    </NavContainer>
  </NavWrapper>
)

Nav.propTypes = {
  currentNav: PropTypes.string,
}

Nav.defaultProps = {
  currentNav: ``,
}

export default Nav
